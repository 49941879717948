import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ClearButton, GradientButton, GridItem, ThemedClearIcon } from '../../../components/StyledComponents/CommonControls'
import { StyledTextField } from '../../../components/StyledComponents/StyledTextField'
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import styles from '../policySearch.module.css';
import { PolicySearchForm } from '../../../entities/Types';
import { DefaultValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { errorTypes } from '../PolicySearch';
import { useAppDispatch } from '../../../app/hooks';
import { resetPolicySearchState } from '../policySearchSlice';

interface Props {
    raiseError: Function
    onSubmit: Function;
    onClear: Function,
    formFields: PolicySearchForm
}

const SearchForm = ({ raiseError, onSubmit, onClear, formFields }: Props) => {
    const dispatch = useAppDispatch();
    const defaultValues: DefaultValues<PolicySearchForm> = {
        policyNumber: "",
    };

    const PolicySearchValidationSchema = yup.object().shape({
        policyNumber: yup.string()
            .matches(/^[a-zA-Z0-9 \-\/.,#:&()_\[\]\\+$*;?='@`]*$/, errorTypes.MatchError)
            .max(100, errorTypes.MaxError)
            .required(errorTypes.RequiredError)
    });
    const form = useForm<PolicySearchForm>({
        defaultValues,
        resolver: yupResolver(PolicySearchValidationSchema)
    });
    const { register, handleSubmit, setValue, reset, watch, clearErrors, setError, formState: { errors } } = form;
    const policyNumberWatch = watch('policyNumber');
    const [policyNumberFocused, setPolicyNumberFocused] = useState<boolean>(false);
    const handleSubmitClick = (formData: PolicySearchForm) => {
        formData.policyNumber = formData.policyNumber.trim();
        if (!formData.policyNumber)
            setError("policyNumber", { type: errorTypes.RequiredError, message: errorTypes.RequiredError });
        onSubmit(formData);
    }

    const clearSearchResults = () => {
        reset(defaultValues);
        clearErrors("policyNumber");
        dispatch(resetPolicySearchState())
        onClear();
    }

    useEffect(() => {
        if (formFields
            && formFields.policyNumber) {
            setValue("policyNumber", formFields.policyNumber);
        }
    }, [formFields]);

    useEffect(() => {
        raiseError(errors.policyNumber?.message);
    }, [errors.policyNumber?.type]);

    useEffect(() => {
        // raiseError(errors.policyNumber?.message);
        // console.log(errors);
    }, [errors]);

    const handleFocus = (e: any) => {
        switch (e?.target?.id) {
            case "policyNumber": {
                setPolicyNumberFocused(true);
                break;
            }
        }
    }
    const handleBlur = (e: any) => {
        switch (e?.target?.id) {
            case "policyNumber": {
                setPolicyNumberFocused(false);
                break;
            }
        }
    }
    return (
        <Box>
            <form noValidate onSubmit={handleSubmit(handleSubmitClick)}>
                <Grid container>
                    <Grid item xs={3} md={5} lg={5} xl={3}>
                        <GridItem sx={{ paddingLeft: "0px" }}>
                            <StyledTextField
                                {...register("policyNumber")}
                                InputLabelProps={{
                                    shrink: !!policyNumberWatch || policyNumberFocused
                                }}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                autoFocus
                                error={errors.policyNumber && errors.policyNumber.message !== "" ? true : false}
                                id="policyNumber" fullWidth label="Policy Number / File Number" data-testid="PolicyNumber"
                            />
                        </GridItem>
                    </Grid>
                    <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }} >
                        <GridItem sx={{ textAlign: 'left' }}>
                            <GradientButton type="submit" size="large" data-testid="SearchButton" className={styles.buttonPadding} variant="contained" startIcon={<SearchIcon sx={{ fontSize: 25 }} />}>
                                <Typography>Search</Typography>
                            </GradientButton>
                            <ClearButton type="reset" size="large" data-testid="ClearButton" onClick={clearSearchResults} className={styles.buttonPadding} sx={{ marginLeft: '23px' }} variant="outlined" startIcon={<ThemedClearIcon sx={{ fontSize: 25 }} />}>
                                <Typography>Clear</Typography>
                            </ClearButton>
                        </GridItem>
                    </Grid>
                </Grid>
            </form>
        </Box>
    )
}

export default SearchForm