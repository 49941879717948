import React, { useEffect } from "react";
import { Box, Container } from "@mui/material";
import Footer from "./layout/footer/Footer";
import Navbar from "./layout/navbar/navbar";
import { useLocation } from "react-router-dom";

const AppLayout = ({ children }: { children: React.ReactNode }) => {

    const location = useLocation();
    const isLoginScreen = location.pathname === "/login";

    return (
        <>
            {
                !isLoginScreen &&
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            minHeight: "100vh",
                        }}>
                        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                            <Container maxWidth={false} sx={{ maxWidth: "1920px" }}>
                                <Navbar />
                                <Box sx={{ flexGrow: 1, margin: "0 auto", width: "100%", marginTop: !isLoginScreen ? "24px" : "0px" }}>
                                    {children}
                                </Box>
                            </Container>
                        </Box>
                        <Container maxWidth={false} sx={{ maxWidth: "1920px", marginTop: '20px' }}>
                            <Box >
                                <Footer />
                            </Box>
                        </Container>
                    </Box>
                </>
            }
            {
                isLoginScreen &&
                <>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            minHeight: "100vh",
                        }}>
                        <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: 'center' }}>
                            <Box sx={{ maxWidth: "1920px" }}>
                                <Navbar isLogin = {true} />
                                <Box sx={{ flexGrow: 1, margin: "0 auto", width: "100%", marginTop: !isLoginScreen ? "24px" : "0px" }}>
                                    {children}
                                </Box>
                            </Box>
                        </Box>
                        <Container maxWidth={false} sx={{
                            maxWidth: "1920px", marginTop: '20px', paddingLeft: "0px !important",
                            paddingRight: "0px !important"
                        }}>
                            <Box sx={{ backgroundColor: 'white', padding: '30px' }}>
                                <Footer />
                            </Box>
                        </Container>
                    </Box>
                </>
            }
        </>
    );
};

export default AppLayout;
