import { useEffect, useRef, useState } from "react";
import {
    Box, Grid, Typography
} from "@mui/material";
import type { DefaultValues } from "react-hook-form";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useForm } from "react-hook-form";
import { ClearButton, GradientButton, GridItem, ThemedClearIcon } from "../../components/StyledComponents/CommonControls";
import { StyledTextField } from "../../components/StyledComponents/StyledTextField";
import { useAppDispatch } from "../../app/hooks";
// import { resetAddressState } from "../addressSearchSlice";
import styles from './policyAddressSearch.module.css';
import { resetPolicyAddressState } from "./policyAddressSearchSlice";
import { errorType, errorMessages } from "./PolicyAddressSearch";
// import { errorMessages, errorType } from "../AddressSearch";

type AddressSearchForm = {
    propertyStreetAddress: string;
    propertyUnitNumber: string;
    propertyCity: string;
    propertyState: string;
    propertyZip: string;
}

interface Props {
    validateAddress: Function
    formFields: AddressSearchForm
    raiseError: Function
    resetErrorMessage: Function
}

function PolicyAddressSearchForm({ validateAddress, formFields, raiseError, resetErrorMessage }: Props) {

    const defaultValues: DefaultValues<AddressSearchForm> = {
        propertyStreetAddress: "",
        propertyUnitNumber: "",
        propertyCity: "",
        propertyState: "",
        propertyZip: "",
    };
    const form = useForm<AddressSearchForm>({
        defaultValues
    });

    const { register, handleSubmit, setError, setValue, reset, watch, clearErrors, getValues, formState } = form;
    const { errors } = formState;
    const dispatch = useAppDispatch();
    const streetAdressWatch = watch('propertyStreetAddress');
    const cityWatch = watch('propertyCity');
    const stateWatch = watch('propertyState');
    const zipWatch = watch('propertyZip');
    const unitNumberWatch = watch('propertyUnitNumber');
    const [streetAdressFocused, setstreetAdressFocused] = useState<boolean>(false);
    const [cityFocused, setcityFocused] = useState<boolean>(false);
    const [stateFocused, setstateFocused] = useState<boolean>(false);
    const [zipFocused, setzipFocused] = useState<boolean>(false);
    const [unitNumberFocused, setunitNumberFocused] = useState<boolean>(false);
    const streetAddressRef = useRef<HTMLInputElement | null>(null);
    const searchButtonClickRef = useRef(false);

    async function onSubmit(formData: AddressSearchForm) {
        searchButtonClickRef.current = true;
        let isValid = false;
        await ValidateFields(formData).then((val) => {
            isValid = val;
        });

        if (!formData.propertyStreetAddress && streetAddressRef.current) {
            streetAddressRef.current.focus();
        }

        if (isValid) {
            validateAddress(formData);
        }
    }

    const handleFocus = (e: any) => {
        switch (e?.target?.id) {
            case "propertyStreetAddress": {
                setstreetAdressFocused(true);
                break;
            }
            case "propertyCity": {
                setcityFocused(true);
                break;
            }
            case "propertyState": {
                setstateFocused(true);
                break;
            }
            case "propertyZip": {
                setzipFocused(true);
                break;
            }
            case "propertyUnitNumber": {
                setunitNumberFocused(true);
                break;
            }
        }
    }
    const handleBlur = (e: any) => {
        switch (e?.target?.id) {
            case "propertyStreetAddress": {
                setstreetAdressFocused(false);
                break;
            }
            case "propertyCity": {
                setcityFocused(false);
                break;
            }
            case "propertyState": {
                setstateFocused(false);
                break;
            }
            case "propertyZip": {
                setzipFocused(false);
                break;
            }
            case "propertyUnitNumber": {
                setunitNumberFocused(false);
                break;
            }
        }
    }

    useEffect(() => {
        resetErrorMessage();
    }, []);


    useEffect(() => {
        if (formFields
            && formFields.propertyStreetAddress !== "") {
            setValue("propertyStreetAddress", formFields.propertyStreetAddress)
            setValue("propertyCity", formFields.propertyCity)
            setValue("propertyState", formFields.propertyState)
            setValue("propertyZip", formFields.propertyZip)
            setValue("propertyUnitNumber", formFields.propertyUnitNumber)
        }
    }, [formFields]);

    const resetErrorOnFields = () => {
        clearErrors("propertyStreetAddress");
        clearErrors("propertyCity");
        clearErrors("propertyState");
        clearErrors("propertyZip");
        clearErrors("propertyUnitNumber");
    }

    const clearSearchResults = () => {
        searchButtonClickRef.current = false;
        reset(defaultValues);
        resetErrorOnFields();
        dispatch(resetPolicyAddressState())
        resetErrorMessage();
    }

    const handleOnkeyUp = (e: any) => {
        if (!searchButtonClickRef.current) {
            return;
        }
        const zipValue = getValues("propertyZip");
        const cityValue = getValues("propertyCity");
        const stateValue = getValues("propertyState");
        if (!errors.propertyZip && zipValue) {
            clearErrors();
        }

        if ((!errors.propertyCity && !errors.propertyState) && cityValue && stateValue) {
            clearErrors();
        }

        if (!zipValue && !cityValue && !stateValue) {
            setError("propertyCity", { type: errorType, message: errorMessages.cityRequiredError });
            setError("propertyState", { type: errorType, message: errorMessages.stateRequiredError });
            setError("propertyZip", { type: errorType, message: errorMessages.zipRequiredError });
            return;
        }

        if (!zipValue && (cityValue || stateValue) && (!cityValue || !stateValue)) {
            if (!cityValue)
                setError("propertyCity", { type: errorType, message: errorMessages.cityRequiredError });

            if (!stateValue)
                setError("propertyState", { type: errorType, message: errorMessages.stateRequiredError });
        }
    }

    const ValidateFields = async (formData: AddressSearchForm): Promise<boolean> => {
        let isValid = false;
        let streetAddress = formData.propertyStreetAddress.trim();
        let city = formData.propertyCity.trim();
        let state = formData.propertyState.trim();
        let zip = formData.propertyZip.trim();
        if (streetAddress === "")
            setError("propertyStreetAddress", { type: errorType, message: errorMessages.streetAddressRequiredError });

        if (city === "" && state === "" && zip === "") {
            setError("propertyState", { type: errorType, message: errorMessages.stateRequiredError });
            setError("propertyCity", { type: errorType, message: errorMessages.cityRequiredError });
            setError("propertyZip", { type: errorType, message: errorMessages.zipRequiredError });
        }
        else if (city !== "" && zip === "" && state === "") {
            setError("propertyState", { type: errorType, message: errorMessages.stateRequiredError });
        }
        else if (state !== "" && zip === "" && city === "") {
            setError("propertyCity", { type: errorType, message: errorMessages.cityRequiredError });
        }
        else if (zip !== "" && streetAddress === "") {
            setError("propertyStreetAddress", { type: errorType, message: errorMessages.streetAddressRequiredError });
        }
        else if (state !== "" && city !== "" && streetAddress === "") {
            setError("propertyStreetAddress", { type: errorType, message: errorMessages.streetAddressRequiredError });
        }
        else {
            isValid = true;
        }
        return Promise.resolve(isValid);
    }

    useEffect(() => {
        raiseError(errors, getValues("propertyCity"), getValues("propertyState"), getValues("propertyZip"));
    }, [errors.propertyStreetAddress, errors.propertyCity, errors.propertyState, errors.propertyZip, errors.propertyUnitNumber]);

    const validateCityNumericFields = (value: any) => {
        if (/\d/.test(value)) {
            return errorMessages.NumericValueInCityError;
        }
        return true;
    }

    return (<>
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}
                noValidate>
                <Grid container>
                    <Grid item xs={4.7}>
                        <GridItem sx={{ paddingLeft: "0px", boxShadow: 0 }}>
                            <StyledTextField
                                {...register("propertyStreetAddress", {
                                    pattern: {
                                        value: /^[a-zA-Z0-9./\-,# ]*$/,
                                        message: errorMessages.InvalidstreetAddressError,
                                    }
                                })}
                                InputLabelProps={{
                                    shrink: !!streetAdressWatch || streetAdressFocused
                                }}
                                inputRef={streetAddressRef}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                autoFocus
                                error={errors.propertyStreetAddress && errors.propertyStreetAddress.message !== "" ? true : false}
                                id="propertyStreetAddress" fullWidth required label="Full Street Address"
                                data-testid="PropertyStreetAddress"
                            />
                        </GridItem>
                    </Grid>
                    <Grid item xs={1}>
                        <GridItem>
                            <StyledTextField
                                {...register("propertyUnitNumber", {
                                    pattern: {
                                        value: /^[a-zA-Z0-9./\-,#]*$/,
                                        message: errorMessages.InvalidUnitError,
                                    }
                                })}
                                InputLabelProps={{
                                    shrink: !!unitNumberWatch || unitNumberFocused
                                }}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                error={errors.propertyUnitNumber && errors.propertyUnitNumber.message !== "" ? true : false}
                                id="propertyUnitNumber" fullWidth label="Unit" variant="outlined"
                                data-testid="PropertyUnitNumber" />
                        </GridItem>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={2} >
                        <GridItem sx={{ paddingLeft: "0px", boxShadow: 0 }}>
                            <StyledTextField
                                {...register("propertyCity", {
                                    pattern: {
                                        value: /^[a-zA-Z0-9\-. ]*$/,
                                        message: errorMessages.InvalidcityError,
                                    },
                                    validate: { validateCityNumericFields }
                                })}
                                InputLabelProps={{
                                    shrink: !!cityWatch || cityFocused
                                }}
                                onKeyUp={handleOnkeyUp}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                error={errors.propertyCity && errors.propertyCity.message !== "" ? true : false}
                                id="propertyCity" fullWidth label="City" required variant="outlined" data-testid="PropertyCity" />
                        </GridItem>
                    </Grid>
                    <Grid item xs={1.5}>
                        <GridItem>
                            <StyledTextField
                                {...register('propertyState', {
                                    pattern: {
                                        value: /^[A-Za-z ]+$/,
                                        message: errorMessages.InvalidstateError,
                                    },
                                })}
                                InputLabelProps={{
                                    shrink: !!stateWatch || stateFocused
                                }}
                                onKeyUp={handleOnkeyUp}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                error={errors.propertyState && errors.propertyState.message !== "" ? true : false}
                                label="State"
                                required
                                variant="outlined"
                                id="propertyState" fullWidth data-testid="PropertyState" />
                        </GridItem>
                    </Grid>
                    <Grid item xs={1}>
                        <GridItem>
                            <StyledTextField
                                {...register('propertyZip', {
                                    pattern: {
                                        value: /^[0-9-]*$/,
                                        message: errorMessages.InvalidZipError,
                                    },
                                    minLength: {
                                        value: 5,
                                        message: errorMessages.MinimumZipError,
                                    },
                                    maxLength: {
                                        value: 10,
                                        message: errorMessages.MaximumZipError,
                                    }
                                })}
                                InputLabelProps={{
                                    shrink: !!zipWatch || zipFocused
                                }}
                                onKeyUp={handleOnkeyUp}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                error={errors.propertyZip && errors.propertyZip.message !== "" ? true : false}
                                id="propertyZip" fullWidth label="ZIP" required variant="outlined" data-testid="PropertyZip" />
                        </GridItem>
                    </Grid>
                    <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }} >
                        <GridItem sx={{ textAlign: 'left' }}>
                            <GradientButton type="submit" size="large" variant="contained" data-testid="SearchButton" className={styles.buttonPadding} startIcon={<SearchIcon sx={{ fontSize: 25 }} />}>
                                <Typography>Search</Typography>
                            </GradientButton>
                            <ClearButton type="reset" size="large" data-testid="ClearButton" className={styles.buttonPadding} onClick={clearSearchResults} sx={{ marginLeft: '23px' }} variant="outlined" startIcon={<ThemedClearIcon sx={{ fontSize: 25 }} />}>
                                <Typography>Clear</Typography>
                            </ClearButton>
                        </GridItem>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </>)
}

export default PolicyAddressSearchForm;