import React, { useEffect, useState } from 'react'
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { resetAddressState, addressList, searchByAddress, setAddressToState } from './addressSearchSlice';
import MatchCodes from '../../utils/enums/MatchCodes';
import {
    SINGLE_MATCH_TEXT,
    MULTIPLE_MATCH_TEXT,
    MULTIPLE_MATCH_RESULT_TEXT,
    NO_MATCH_FOUND_TEXT,
    NO_MATCH__FOUND_RESULT_TEXT,
    NO_COVERAGE_TEXT, NO_COVERAGE_RESULT_TEXT, STREET_VALIDATION_ERROR_TEXT, keyPageNo, AddressSearchErrorMessages,
    AddressSearch_Logging_Event
} from '../../utils/constants/constants';
import { AddressSearchRequest } from '../../entities/ApiModel';
import SearchForm from './searchForm/SearchForm';
import { AddressSearchForm } from '../../entities/Types';
import PropertyTable from '../../components/ui/propertyTable/PropertyTable';
import MatchStatus from '../../components/ui/matchStatus/MatchStatus';
import styles from './addressSearch.module.css';
import { ADDRESS_TAB, ADDRESS_TAB_HEADER, POLICY_ADDRESS_TAB } from '../../utils/constants/tabConstants';
import { clearSessionStorageByKeyStartingWith } from '../../utils/common/commonUtils';
import LoadingProgressModel from '../../components/ui/circularProgress/LoadingProgress';
import TabHeader from '../../components/ui/tabHeader/TabHeader';
import ErrorMessage from '../../components/ui/errorMessage/ErrorMessage';
import { ValidationText } from '../../components/StyledComponents/CommonControls';
import PolicyAddressSearch from '../policyAddressSearch/PolicyAddressSearch';
import { tabIdentifier } from '../../components/ui/propertyTable/tabIdentifierSlice';
import { StyledRadioButton } from '../../components/StyledComponents/StyledRadioButton';

export const errorType: string = "Required"
export const errorMessages = {
    streetAddressRequiredError: "StreetRequired",
    InvalidstreetAddressError: "InvalidStreetAddress",
    cityRequiredError: "CityRequired",
    InvalidcityError: "InvalidCity",
    stateRequiredError: "StateRequired",
    InvalidstateError: "InvalidState",
    zipRequiredError: "ZipRequired",
    InvalidZipError: "InvalidZip",
    MinimumZipError: "MinimumZip",
    MaximumZipError: "MaximumZip",
    InvalidUnitError: "InvalidUnitNumber",
    NumericValueInCityError: "NumericValueInCityError",
}

function AddressSearch() {
    const addressListState = useAppSelector(addressList);
    const dispatch = useAppDispatch();
    const [matchCode, setMatchCode] = useState<string>("");
    const [matchText, setMatchText] = useState<string>("");
    const [resultText, setResultText] = useState<string>("");
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const tabState = useAppSelector(tabIdentifier);

    const [isAssesementAddressSearch, setAssesementAddressSearch] = React.useState(true);
    const [isPolicyAddressSearch, setPolicyAddressSearch] = React.useState(false);

    async function ValidateAddress(formData: AddressSearchForm) {
        dispatch(resetAddressState())
        clearSessionStorageByKeyStartingWith(keyPageNo + ADDRESS_TAB);
        setMatchCode("");
        setErrorMessage("");
        setLoading(true);
        let addressRequest: AddressSearchRequest = {
            address: formData.propertyStreetAddress.trim(),
            city: formData.propertyCity.trim(),
            state: formData.propertyState.trim(),
            zip: formData.propertyZip.trim(),
            unit_number: formData.propertyUnitNumber.trim(),
        };
        dispatch(setAddressToState(formData))
        dispatch(searchByAddress(addressRequest))
    }

    useEffect(() => {
        if (tabState && tabState.subTabName === POLICY_ADDRESS_TAB) {
            setTimeout(function () {
                setAssesementAddressSearch(false);
                setPolicyAddressSearch(true);
            }, 0);
        }
        else if (tabState.tabName === ADDRESS_TAB) {
            setTimeout(function () {
                setAssesementAddressSearch(true);
                setPolicyAddressSearch(false);
            }, 0);
        }
    }, []);

    useEffect(() => {
        if (addressListState
            && addressListState.addresses
            && addressListState.addresses.match_code !== "") {
            setMatchCode(addressListState.addresses.match_code);
            setLoading(false);
        } else {
            setMatchCode("");
        }

        if (addressListState.status === "failed") {
            setLoading(false);
        }
    }, [addressListState]);

    useEffect(() => {
        if (matchCode) {
            switch (matchCode) {
                case MatchCodes.NO_MATCH.toString(): {
                    setMatchText(NO_MATCH_FOUND_TEXT);
                    setResultText(NO_MATCH__FOUND_RESULT_TEXT);
                    break;
                }
                case MatchCodes.UNIQUE_MATCH.toString(): {
                    setMatchText(SINGLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.MULTIPLE_MATCH.toString(): {
                    setMatchText(MULTIPLE_MATCH_TEXT);
                    setResultText(MULTIPLE_MATCH_RESULT_TEXT);
                    break;
                }
                case MatchCodes.NO_COVERAGE.toString(): {
                    setMatchText(NO_COVERAGE_TEXT);
                    setResultText(NO_COVERAGE_RESULT_TEXT);
                    break;
                }
                case MatchCodes.INVALID_ADDRESS.toString(): {
                    setMatchText(STREET_VALIDATION_ERROR_TEXT);
                    break;
                }
                default: {
                    setMatchText("");
                    setResultText("");
                }
            }
        } else {
            setMatchText("");
            setResultText("");
        }
    }, [matchCode]);

    const resetError = () => {
        setErrorMessage("");
    }

    // function hasNonRequiredError(errors: any) {
    //     return Object.values(errors).some((error: any) => error.type !== "Required");
    // }

    const raiseError = (errors: any, city: any, state: any, zip: any) => {
        if (addressListState.addresses.match_code && Object.keys(errors).length === 0)
            setMatchCode(addressListState.addresses.match_code);

        if (Object.keys(errors).length === 0)
            setErrorMessage("");

        if (matchCode === MatchCodes.INVALID_ADDRESS.toString() && Object.keys(errors).length !== 0)
            setMatchCode("");

        if (errors.propertyStreetAddress?.type === "Required" && errors.propertyCity?.type === "Required" &&
            errors.propertyState?.type === "Required" && errors.propertyZip?.type === "Required") {
            setErrorMessage(AddressSearchErrorMessages.All_Fields_Required);
            return;
        }

        if (errors.propertyStreetAddress?.type === "Required" &&
            (errors.propertyState?.type === "Required" || errors.propertyCity?.type === "Required")) {
            setErrorMessage(AddressSearchErrorMessages.All_Fields_Required);
            return;
        }

        if (errors.propertyStreetAddress?.type === "Required") {
            setErrorMessage(AddressSearchErrorMessages.Street_Address_Required);
        }

        if (errors.propertyStreetAddress?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_Street_Address);
        }

        else if (errors.propertyCity?.message === "NumericValueInCityError") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_City_Numeric_Error);
        }

        else if (errors.propertyCity?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_City_Field);
        }

        else if (errors.propertyState?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_State_Field);
        }

        else if (errors.propertyZip?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_Zip_Field);
        }

        else if (errors.propertyUnitNumber?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_Unit_Field);
        }

        else if (errors.propertyZip?.type === "minLength") {
            setErrorMessage(AddressSearchErrorMessages.Minimum_Zip_Error);
        }

        else if (errors.propertyZip?.type === "maxLength") {
            setErrorMessage(AddressSearchErrorMessages.Maximum_Zip_Error);
        }

        else if (!zip && (!city || !state)) {
            if ((errors.propertyCity?.type === "Required" ||
                errors.propertyState?.type === "Required" || errors.propertyZip?.type === "Required"))
                setErrorMessage(AddressSearchErrorMessages.City_State_Zip_Required);
            return;
        }
    }

    const handleAssesementAddressSearchClick = () => {
        setPolicyAddressSearch(false)
        setAssesementAddressSearch(true)
    }

    const handlePolicyAddressSearchClick = () => {
        setAssesementAddressSearch(false)
        setPolicyAddressSearch(true)
    }

    let columns = [
        { id: 'apn', label: 'APN', minWidth: 180 },
        { id: 'ownerName', label: 'OWNER NAME', minWidth: 290 },
        {
            id: 'address',
            label: 'ADDRESS',
            minWidth: 195,
        },
        {
            id: 'city',
            label: 'CITY',
            minWidth: 160,
        },
        {
            id: 'state',
            label: 'STATE',
            minWidth: 60,
        },
        {
            id: 'formattedZip',
            label: 'ZIP',
            minWidth: 135,
            format: (value: number) => value.toFixed(2),
        },
        {
            id: 'landUse',
            label: 'LAND USE',
            minWidth: 200,
        },
        {
            id: 'briefLegal',
            label: 'ASSESSMENT LEGAL',
            minWidth: 200,
        }
    ];

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <TabPanel value="addressTab" sx={{ padding: 0 }}>
                <Box className={styles.boxContent}>
                    <FormControl>
                        <RadioGroup
                            row
                            name="row-radio-buttons-group">
                            <StyledRadioButton minWidth="240px" checked={isAssesementAddressSearch} value="assessmentAddressSearch" sx={{ paddingRight: "15px" }}
                                control={<Radio />} onChange={handleAssesementAddressSearchClick} label="Assessment Address Search" />
                            <StyledRadioButton checked={isPolicyAddressSearch} value="policyAddressSearch" onChange={handlePolicyAddressSearchClick}
                                control={<Radio />} label="Policy Address Search" />
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{ marginTop: '20px', marginBottom: '15px' }}>
                        <ValidationText>*City & State or ZIP Required</ValidationText>
                    </Box>
                </Box>
                {
                    isAssesementAddressSearch
                    &&
                    <>
                        <Box className={styles.boxLayout}>
                            <SearchForm validateAddress={ValidateAddress} raiseError={raiseError} resetErrorMessage={resetError} formFields={addressListState?.formFields} />
                            {
                                errorMessage
                                &&
                                <ErrorMessage errorMessageText={errorMessage} />
                            }
                            {
                                isAssesementAddressSearch &&
                                matchCode &&
                                matchCode === MatchCodes.INVALID_ADDRESS.toString()
                                &&
                                <MatchStatus matchCode={matchCode} matchText={matchText} resultText={resultText} />
                            }
                        </Box>
                    </>
                }
                {
                    isPolicyAddressSearch
                    &&
                    <>
                        <PolicyAddressSearch />
                    </>
                }
                {
                    isAssesementAddressSearch &&
                    matchCode &&
                    matchCode !== MatchCodes.INVALID_ADDRESS.toString()
                    &&
                    <MatchStatus matchCode={matchCode} matchText={matchText} resultText={resultText} />
                }
                {
                    isAssesementAddressSearch &&
                    addressListState
                    && addressListState.addresses
                    && addressListState.addresses.properties.length > 0
                    &&
                    <div>
                        <Box sx={{ mt: '15px', width: '100%' }}>
                            <PropertyTable
                                tabName={ADDRESS_TAB}
                                loggingEventName={AddressSearch_Logging_Event.Search_Event}
                                columns={columns}
                                tabHeader={ADDRESS_TAB_HEADER}
                                totalRowCount={addressListState.addresses.total_count}
                                propertyData={addressListState.addresses.properties}
                            />
                        </Box>
                    </div>
                }
            </TabPanel >
        </>
    )
}

export default AddressSearch;