import { useEffect, useState } from 'react'
import { Box } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { searchByPolicyAddress, policyAddressList, resetPolicyAddressState, setPolicyAddressToState } from './policyAddressSearchSlice';
import MatchCodes from '../../utils/enums/MatchCodes';
import {
    SINGLE_MATCH_TEXT,
    MULTIPLE_MATCH_TEXT,
    MULTIPLE_MATCH_RESULT_TEXT,
    NO_MATCH_FOUND_TEXT,
    NO_MATCH__FOUND_RESULT_TEXT,
    NO_COVERAGE_TEXT, NO_COVERAGE_RESULT_TEXT, STREET_VALIDATION_ERROR_TEXT, keyPageNo, AddressSearchErrorMessages,
    keyEntriesPerPage
} from '../../utils/constants/constants';
import { AddressSearchRequest } from '../../entities/ApiModel';
import { AddressSearchForm } from '../../entities/Types';
import PropertyTable from '../../components/ui/propertyTable/PropertyTable';
import MatchStatus from '../../components/ui/matchStatus/MatchStatus';
import styles from './policyAddressSearch.module.css';

//import styles from './policyAddressSearch.module.css';
import { ADDRESS_TAB, ADDRESS_TAB_HEADER, POLICY_ADDRESS_TAB } from '../../utils/constants/tabConstants';
import { clearSessionStorageByKeyStartingWith, getSessionStorage, setSessionStorage } from '../../utils/common/commonUtils';
import LoadingProgressModel from '../../components/ui/circularProgress/LoadingProgress';
import TabHeader from '../../components/ui/tabHeader/TabHeader';
import ErrorMessage from '../../components/ui/errorMessage/ErrorMessage';
import { ValidationText } from '../../components/StyledComponents/CommonControls';
import PolicyAddressSearchForm from './PolicyAddressSearchForm';
import { PolicyAddressSearchRequest } from '../../entities/ApiModel/PolicyAddressSearchRequest';
import PolicySearchResult from '../policySearch/searchResult/PolicySearchResult';

export const errorType: string = "Required"
export const errorMessages = {
    streetAddressRequiredError: "StreetRequired",
    InvalidstreetAddressError: "InvalidStreetAddress",
    cityRequiredError: "CityRequired",
    InvalidcityError: "InvalidCity",
    stateRequiredError: "StateRequired",
    InvalidstateError: "InvalidState",
    zipRequiredError: "ZipRequired",
    InvalidZipError: "InvalidZip",
    MinimumZipError: "MinimumZip",
    MaximumZipError: "MaximumZip",
    InvalidUnitError: "InvalidUnitNumber",
    NumericValueInCityError: "NumericValueInCityError",
}

function PolicyAddressSearch() {
    const policyAddressListState = useAppSelector(policyAddressList);
    const dispatch = useAppDispatch();
    const [matchCode, setMatchCode] = useState<string>("");
    const [matchText, setMatchText] = useState<string>("");
    const [resultText, setResultText] = useState<string>("");
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    async function ValidateAddress(formData: AddressSearchForm) {
        dispatch(resetPolicyAddressState())
        clearSessionStorageByKeyStartingWith(keyPageNo + POLICY_ADDRESS_TAB);
        setMatchCode("");
        setErrorMessage("");
        setLoading(true);

        const defaultPageNo = 1;
        const defaultRowsPerPage = 10;
        setSessionStorage(keyPageNo + POLICY_ADDRESS_TAB, defaultPageNo);
        let page = defaultPageNo;//parseInt(getSessionStorage(keyPageNo + POLICY_SEARCH_TAB) ?? defaultPageNo.toString());
        let rowsPerPage = parseInt(getSessionStorage(keyEntriesPerPage + POLICY_ADDRESS_TAB) ?? defaultRowsPerPage.toString())

        let addressRequest: PolicyAddressSearchRequest = {
            property_full_street_address: formData.propertyStreetAddress.trim(),
            property_city: formData.propertyCity.trim(),
            property_state_code: formData.propertyState.trim(),
            property_zipcode: formData.propertyZip.trim(),
            property_unit_number: formData.propertyUnitNumber.trim(),
            offset_value: ((page - 1) * rowsPerPage).toString(),
            fetchnext_value: ((page - 1) * rowsPerPage + rowsPerPage).toString()
        };
        formData.pageNo = page;
        dispatch(setPolicyAddressToState(JSON.parse(JSON.stringify(formData))))
        dispatch(searchByPolicyAddress(addressRequest))
    }

    async function handleFetchNext(page: number, pageNumber: string, noOfRows: string) {
        setLoading(true);
        let request: PolicyAddressSearchRequest = {
            property_full_street_address: policyAddressListState.formFields.propertyStreetAddress.trim(),
            property_city: policyAddressListState.formFields.propertyCity.trim(),
            property_state_code: policyAddressListState.formFields.propertyState.trim(),
            property_zipcode: policyAddressListState.formFields.propertyZip.trim(),
            property_unit_number: policyAddressListState.formFields.propertyUnitNumber.trim(),
            offset_value: pageNumber.toString(),
            fetchnext_value: noOfRows.toString(),
        }

        setMatchCode("");
        setMatchText("");
        let formData = { ...policyAddressListState?.formFields, "pageNo": page }
        dispatch(searchByPolicyAddress(request));
        dispatch(setPolicyAddressToState(JSON.parse(JSON.stringify(formData))));
    }

    // async function handleFetchNext(page: number, pageNumber: string, noOfRows: string) {
    //     setLoading(true);
    //     let policy_file_Number = policySearchState?.formFields?.policyNumber;
    //     let request: PolicySearchRequest = {
    //         policy_file_number: policy_file_Number,
    //         offset_value: pageNumber.toString(),
    //         fetchnext_value: noOfRows.toString(),
    //         isPaginationOperation: true
    //     }
    //     setMatchCode("");
    //     setMatchText("");
    //     // let formData = ;
    //     let formData = { ...policySearchState?.formFields, "pageNo": page }
    //     dispatch(setPolicyNumberToState(formData));
    //     dispatch(searchByPolicyNumber(request));
    // }

    useEffect(() => {
        if (policyAddressListState
            && policyAddressListState.addresses
            && policyAddressListState.addresses.match_code !== "" && !policyAddressListState.addresses.isExecuting) {
            setMatchCode(policyAddressListState.addresses.match_code);
            setLoading(false);
        } else {
            setMatchCode("");
        }

        if (policyAddressListState.status === "failed") {
            setLoading(false);
        }
    }, [policyAddressListState]);

    useEffect(() => {
        if (matchCode) {
            switch (matchCode) {
                case MatchCodes.NO_MATCH.toString(): {
                    setMatchText(NO_MATCH_FOUND_TEXT);
                    setResultText(NO_MATCH__FOUND_RESULT_TEXT);
                    break;
                }
                case MatchCodes.UNIQUE_MATCH.toString(): {
                    setMatchText(SINGLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.MULTIPLE_MATCH.toString(): {
                    setMatchText(MULTIPLE_MATCH_TEXT);
                    setResultText(MULTIPLE_MATCH_RESULT_TEXT);
                    break;
                }
                case MatchCodes.NO_COVERAGE.toString(): {
                    setMatchText(NO_COVERAGE_TEXT);
                    setResultText(NO_COVERAGE_RESULT_TEXT);
                    break;
                }
                case MatchCodes.INVALID_ADDRESS.toString(): {
                    setMatchText(STREET_VALIDATION_ERROR_TEXT);
                    break;
                }
                default: {
                    setMatchText("");
                    setResultText("");
                }
            }
        } else {
            setMatchText("");
            setResultText("");
        }
    }, [matchCode]);

    const resetError = () => {
        setErrorMessage("");
    }

    const raiseError = (errors: any, city: any, state: any, zip: any) => {
        if (policyAddressListState.addresses.match_code && Object.keys(errors).length === 0)
            setMatchCode(policyAddressListState.addresses.match_code);

        if (Object.keys(errors).length === 0)
            setErrorMessage("");

        if (matchCode === MatchCodes.INVALID_ADDRESS.toString() && Object.keys(errors).length !== 0)
            setMatchCode("");

        if (errors.propertyStreetAddress?.type === "Required" && errors.propertyCity?.type === "Required" &&
            errors.propertyState?.type === "Required" && errors.propertyZip?.type === "Required") {
            setErrorMessage(AddressSearchErrorMessages.All_Fields_Required);
            return;
        }

        if (errors.propertyStreetAddress?.type === "Required" &&
            (errors.propertyState?.type === "Required" || errors.propertyCity?.type === "Required")) {
            setErrorMessage(AddressSearchErrorMessages.All_Fields_Required);
            return;
        }

        if (errors.propertyStreetAddress?.type === "Required") {
            setErrorMessage(AddressSearchErrorMessages.Street_Address_Required);
        }

        // if (errors.propertyCity?.type === "Required" ||
        //     errors.propertyState?.type === "Required" || errors.propertyZip?.type === "Required") {
        //     setErrorMessage(AddressSearchErrorMessages.City_State_Zip_Required);
        // }

        if (errors.propertyStreetAddress?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_Street_Address);
        }

        else if (errors.propertyCity?.message === "NumericValueInCityError") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_City_Numeric_Error);
        }

        else if (errors.propertyCity?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_City_Field);
        }

        else if (errors.propertyState?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_State_Field);
        }

        else if (errors.propertyZip?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_Zip_Field);
        }

        else if (errors.propertyUnitNumber?.type === "pattern") {
            setErrorMessage(AddressSearchErrorMessages.Invalid_Unit_Field);
        }

        else if (errors.propertyZip?.type === "minLength") {
            setErrorMessage(AddressSearchErrorMessages.Minimum_Zip_Error);
        }

        else if (errors.propertyZip?.type === "maxLength") {
            setErrorMessage(AddressSearchErrorMessages.Maximum_Zip_Error);
        }

        else if (!zip && (!city || !state)) {
            if ((errors.propertyCity?.type === "Required" ||
                errors.propertyState?.type === "Required" || errors.propertyZip?.type === "Required"))
                setErrorMessage(AddressSearchErrorMessages.City_State_Zip_Required);
            return;
        }
    }

    const handleChangeRowsPerPage = () => {
        dispatch(resetPolicyAddressState());
    }

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <Box>
                <div className="">
                    <Box className={styles.boxLayout}>
                        <PolicyAddressSearchForm validateAddress={ValidateAddress} raiseError={raiseError} resetErrorMessage={resetError} formFields={policyAddressListState?.formFields} />
                        {
                            errorMessage
                            &&
                            <ErrorMessage errorMessageText={errorMessage} />
                        }
                        {
                            matchCode &&
                            matchCode === MatchCodes.INVALID_ADDRESS.toString()
                            &&
                            <MatchStatus matchCode={matchCode} matchText={matchText} resultText={resultText} />
                        }
                    </Box>
                    {
                        matchCode
                        &&
                        matchCode !== MatchCodes.INVALID_ADDRESS.toString()
                        &&
                        <MatchStatus matchCode={matchCode} matchText={matchText} />
                    }
                    {
                        policyAddressListState
                        && policyAddressListState.addresses
                        && Object.keys(policyAddressListState.addresses.properties).length > 0
                        &&
                        <PolicySearchResult
                            totalRowCount={policyAddressListState?.addresses?.total_count ?? 0}
                            tabName={ADDRESS_TAB}
                            subTabName={POLICY_ADDRESS_TAB}
                            loggingEventName={POLICY_ADDRESS_TAB}
                            fetchNextData={handleFetchNext}
                            propertyList={policyAddressListState.addresses.properties}
                            changeRowsPerPage={handleChangeRowsPerPage} />
                    }
                </div>
            </Box>
        </>
    )
}

export default PolicyAddressSearch;